import './app/styles/App.css';
import Home from './app/screens/Home';
import React, { useState } from 'react';

function App() {
  const [homeLoaded, setHomeLoaded] = useState(false);

  return (
    <div className="App">
      <Home onLoaded={() => setHomeLoaded(true)} isLoaded={homeLoaded} />
    </div>
  );
}

export default App;
