import React, { useState, useEffect, useRef } from 'react';
import '../styles/homeStyle.css';
// import heroSky from '../assets/hero/hero-bg.png';
import heroSmoke from '../assets/hero/hero-smoke.svg';
import heroSavanna from '../assets/hero/hero-savanna.png';
import WindowPopUp from '../components/windows-popup';
import computerFace from '../assets/hero/computer-face.svg';
import heroMan from '../assets/hero/man.png';
import flower from '../assets/hero/flower.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faArrowTurnDown } from '@fortawesome/free-solid-svg-icons';
import projectJSON from '../config/projects.json';
// import bubbleTalkCover from '../assets/projects/bubbleTalk.png';
// import wayCover from '../assets/projects/way.png';

const Home = ({ onLoaded, isLoaded }) => {

  useEffect(() => {
    const imagesToLoad = [heroSmoke, heroSavanna, computerFace, heroMan, flower];
    const loadImages = imagesToLoad.map((src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          resolve();
        };
      });
    });

    Promise.all(loadImages).then(() => {
      onLoaded();
    });
  }, [onLoaded]);

  // const projectImages = {
  //   'bubbleTalk': bubbleTalkCover,
  //   'way': wayCover
  // };

  const [heroSavannaStyle, setHeroSavannaStyle] = useState({});
  useEffect(() => {
    const updateHeroSavannaStyle = () => {
      const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
      const screenWidth = window.innerWidth;
      const conditionLeftSide = (44 * rem) / screenWidth;
      const conditionRightSide = 492 / 1400;

      if (conditionLeftSide <= conditionRightSide) {
        setHeroSavannaStyle({ width: '100%', height: 'auto' });
      } else {
        setHeroSavannaStyle({ height: '100%', width: 'auto' });
      }
    };
    window.addEventListener('resize', updateHeroSavannaStyle);
    updateHeroSavannaStyle();
    return () => window.removeEventListener('resize', updateHeroSavannaStyle);
  }, []);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupContainerRef = useRef(null);
  const [heroText, setHeroText] = useState(
    [
      {
        title: "UI Design",
        content: [
          "<li>Figma</li>",
          "<li>Webflow</li>",
        ]
      },
      {
        title: "Frontend Frameworks",
        content: [
          "<li>Next.js</li>",
          "<li>React.js</li>",
          "<li>React Native</li>",
          "<li>Vue.js</li>",
          "<li>AngularJS</li>",
          "<li>Angular</li>"
        ]
      },
      {
        title: "Coding Languages",
        content: [
          "<li>Javascript</li>",
          "<li>Typescript</li>",
          "<li>Python</li>",
          "<li>Swift</li>",
          "<li>Java</li>",
          "<li>C#</li>",
          "<li>C++</li>",
          "<li>C</li>"
        ]
      },
      {
        title: "Adobe Creative Suite",
        content: [
          "<li>Photoshop</li>",
          "<li>Illustrator</li>",
          "<li>After Effect</li>",
          "<li>Premiere</li>",
          "<li>Animate</li>"
        ]
      },
      {
        title: "Special Skill Set",
        content: [
          "<li>Design for Accessibility</li>",
          "<li>Agile PM Methodologies</li>",
          "<li>Entrepreneurial Mindset</li>",
          "<li>Parameterized Animation</li>",
        ]
      }
    ]
  );
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsPopupVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.05,
      }
    );

    if (popupContainerRef.current) {
      observer.observe(popupContainerRef.current);
    }

    return () => {
      if (popupContainerRef.current) {
        observer.unobserve(popupContainerRef.current);
      }
    };
  }, []);

  const aboutTitleRef = useRef(null);
  const [isAboutTitleVisible, setIsAboutTitleVisible] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsAboutTitleVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    if (aboutTitleRef.current) {
      observer.observe(aboutTitleRef.current);
    }

    return () => {
      if (aboutTitleRef.current) {
        observer.unobserve(aboutTitleRef.current);
      }
    };
  }, []);

  const [projects, setProjects] = useState([]);
  useEffect(() => {
    setProjects(projectJSON);
  }, [projectJSON])

  const [failedImages, setFailedImages] = useState(new Set());

  const handleImageError = (projectIdentifier) => {
    setFailedImages((prevFailedImages) => new Set(prevFailedImages.add(projectIdentifier)));
  };

  return (
    <div className="container">
      {!isLoaded && (
        <div className="loader-container" style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100 }}>
          <div className="loaderball"></div>
        </div>
      )}
      <div className="content">
        <div className="titleContainer">
          <a href="#hero" className="titleName"><img src={flower} alt="flower"></img>Ziqian Wang.</a>
          <div className="titleBtnContainer">
            <a href="#about" className="titleBtn">About.</a>
            <a href="#projects" className="titleBtn">Projects.</a>
            {/* <a href="#contact" className="titleBtn">Contact.</a> */}
          </div>
        </div>

        <div id='hero' className="hero">
          <a href="#about" className="downArrowContainer" style={{ display: isAboutTitleVisible ? 'none' : 'flex' }}>
            <div className="downArrow">
              <FontAwesomeIcon icon={faAnglesDown} />
            </div>
          </a>
          <div className="heroMan">
            <img src={heroMan} alt="heroMan" className="man-img" />
            <img src={computerFace} alt="computerFace" className="computer-face" />
          </div>
          <div className="hero-bg">
            {/* <img src={heroSky} alt="heroSky" className="heroSky" /> */}
            <div className="heroGrid" ></div>
            <div className="heroSun">
              <div className="sunNoise"></div>
              <div className="sunCover"></div>
              <div className="sunCore"></div>
              <div className="heroSunInner"></div>
            </div>
            <img src={heroSmoke} alt="heroSmoke" style={heroSavannaStyle} className="heroSmoke" />
            <img src={heroSavanna} alt="heroSavanna" style={heroSavannaStyle} className="heroSavanna" />
          </div>
        </div>

        <div id='about'>
          <div className="about">
            <div className="aboutContent">
              <h1 ref={aboutTitleRef}>About.</h1>
              <div>
                <p>Ziqian Wang <span className="small-text">(Zee-Chen Wong)</span> is a <b>designer + developer</b> who blends the mindfulness of design with the technology of software development to craft human-centered digital experiences.
                  With expertise in frontend frameworks like React and React Native, alongside a toolkit of programming languages, he brings unique and captivating creations to the digital realm. Currently residing in the city of St. Louis, Ziqian offers his global services, ready to tackle any design challenges.</p>
              </div>
            </div>
          </div>
          {/* <div className="toolbox" ref={popupContainerRef}>
          <div className="aboutContent">
            <h1>Toolbox.</h1>
            <div>
              <div className="popup-container">
                {isPopupVisible && heroText.map((popup, index) => (
                  <WindowPopUp
                    key={index}
                    title={popup.title}
                    content={popup.content}
                  />
                ))}
              </div>
            </div>
          </div>
        </div> */}
        </div>

        <div id='projects'>
          <div className="projectsContainer">
            <div className="projectsIntro">
              <div className="projectsTitle">
                <FontAwesomeIcon icon={faArrowTurnDown}></FontAwesomeIcon>
                <h2>Projects</h2>
              </div>
              <div className="titleSeparator"></div>
              <div className="projectDesc">
              <div className="descBlock">
                  <p className="descBlockTitle">Specialty</p>
                  <div className="descBlockContent">
                    <p>Rapid Prototype Dev.</p>
                    <p>Accessible Design</p>
                    <p>Agile Project Management</p>
                  </div>
                </div>
                <div className="descBlock">
                  <p className="descBlockTitle">discipline</p>
                  <div className="descBlockContent">
                    <p>UI/UX Design</p>
                    <p>Frontend Development</p>
                    <p>UX Research</p>
                    <p>Branding</p>
                  </div>
                </div>
                <div className="descBlock">
                  <p className="descBlockTitle">Design Tools</p>
                  <div className="descBlockContent">
                    <p>Figma</p>
                    <p>Adobe Illustrator</p>
                    <p>Adobe Photoshop</p>
                  </div>
                </div>
                <div className="descBlock">
                  <p className="descBlockTitle">Dev Frameworks</p>
                  <div className="descBlockContent">
                    <p>React</p>
                    <p>Next</p>
                    <p>React Native</p>
                    <p>SwiftUI</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="projects">
              {
                projects
                  .slice()
                  .sort((a, b) => {
                    if (a.cover && !b.cover) {
                      return -1;
                    }
                    if (!a.cover && b.cover) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((project, index) => (
                    <a key={index} className="projectCard" href={project.link} target="_blank" rel="noreferrer">
                      <div className="projectCardTop">
                        {project.cover ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/${project.cover}`}
                            className="projectImage"
                            alt={project.title}
                            loading="lazy"
                            onError={() => handleImageError(project.title)}
                          />
                        ) : (
                          <div className="projectImagePlaceholder">
                            <p>Coming Soon</p>
                          </div>
                        )}
                      </div>
                      <div className="projectCardText">
                        <h1>{project.title}</h1>
                        <p>{project.description}</p>
                      </div>
                    </a>
                  ))
              }
            </div>

          </div>
        </div>

        <div id='contact'>

        </div>

        <div className="footer">
          <p>&copy; 2021 Ziqian Wang. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
